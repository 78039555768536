.container {
	height: 100%;
	width: 100%;
}

.container-cropper {
	height: 400px;
	padding: 10px;
}

 .cropper {
	height: 90%;
	position: relative;
}

.slider {
	height: 10%;
	display: flex;
	align-items: center;
	margin: auto;
	width: 60%;
}

.container-buttons {
	border: 1px solid #f5f5f5;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.crop-upper {
	height: 400px;
}

.MuiButtonBase-root {
    margin-left: 10px;
}

.container-buttons button {
	margin-left: 10px;
}