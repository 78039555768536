.cursor_pointer{
    cursor: pointer !important;
  }

  .MuiInputAdornment-root {
      cursor: pointer;
  }

  .cIapel{
    min-height: 10px !important;
  }